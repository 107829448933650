import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import AuthService from '../../features/auth/auth.service'

import Dialog from '../../services/Dialogs'

import Pagination from "../../services/Pagination";
import { Table, Form, Dropdown } from 'react-bootstrap'



import ModalAltaCiudadano from './ModalAltaCiudadano'

import ModalFichaEmpleado from './ModalFichaEmpleado'


import ModalCambiarFoto from '../ModalCambiarFoto'

import ModalReingresoEmpleado from './ModalReingresoEmpleado'






import ModalReporteNomina from './ModalReporteNomina'


import ModalEditarDatosEmpleado from './ModalEditarDatosEmpleado'
import ModalEditarInformacionNominaEmpleado from './ModalEditarInformacionNominaEmpleado'





import ModalMovimientoEmpleado from './ModalMovimientoEmpleado'
import ModalCorreccionDatos from './ModalCorreccionDatos'
import ModalBaja from './ModalBaja'





import ModalAseguramiento from './ModalAseguramiento'


import { SERVER_ } from  '../../config';


const Recursos_humanos = () =>{

  const currentUser = AuthService.getCurrentUser()

  const dialog = new Dialog();
 

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint('config')
            setConfig(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }





      React.useEffect(() => {
        busqueda()
        cargar()
      }, []);



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 40
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  // const currentUser  = AuthService.getCurrentUser();

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint('recursos_humanos/busqueda/'+arreglo_nominas+'/busqueda?where='+text_busqueda+'&skip='+skip+'&limit='+sessionsPerPage)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }



  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }





  const [ modalAltaCiudadano, setModalAltaCiudadano] = useState<boolean>(false)
  const [ config, setConfig ] = useState<any>({})
  const onHideModalAltaCiudadano = () =>{
      setModalAltaCiudadano(false)
  }

  const abrirModalNuevoAsesor = async() =>{
    try{
      setModalAltaCiudadano(true)
      // let res = await RequestService.getEndPoint('config')
      // setConfig(res.data)
    }
    catch(e){
      setModalAltaCiudadano(false)
    }
  }



  const [ arreglo_nominas, setArregloNominas ] = useState<any>([true,true,true,true,true])


  const todos = () =>{
    setArregloNominas([true,true,true,true,true])
  }
  const ninguno = () =>{
    setArregloNominas([false,false,false,false,false,false])
  }


  const [ uuid_empleado_seleccionado, setUUIDEmpleadoSeleccionado ] = useState<string>('')
  const [ empleado_seleccionado, setEmpleadoSeleccionado ] = useState<any>({})
  const [ show_ModalFichaEmpleado, setModalFichaEmpleado ] = useState<boolean>(false)
  const handleShow_ModalFichaEmpleado = (uuid_empleado:string) => {
    setUUIDEmpleadoSeleccionado(uuid_empleado)
    setModalFichaEmpleado(true)
  }
  const handleClose_ModalFichaEmpleado = () => {
    setModalFichaEmpleado(false)
  }






  const [ show_ModalCambiarFoto, setModalCambiarFoto ] = useState<boolean>(false)
  const handleShow_ModalCambiarFoto = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalCambiarFoto(true)
  }
  const handleClose_ModalCambiarFoto = () => {
    setModalCambiarFoto(false)
  }








  const [ show_ModalMovimientoEmpleado, setModalMovimientoEmpleado ] = useState<boolean>(false)
  const handleShow_ModalMovimientoEmpleado = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalMovimientoEmpleado(true)
  }
  const handleClose_ModalMovimientoEmpleado = () => {
    setModalMovimientoEmpleado(false)
  }




  // const [ show_ModalMovimientos, setModalMovimientos ] = useState<boolean>(false)
  // const handleShow_ModalMovimientos = () => {
  //   setModalMovimientos(true)
  // }
  // const handleClose_ModalMovimientos = () => {
  //   setModalMovimientos(false)
  // }


  const [ show_ModalCorreccionDatos, setModalCorreccionDatos ] = useState<boolean>(false)
  const handleShow_ModalCorreccionDatos = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalCorreccionDatos(true)
  }
  const handleClose_ModalCorreccionDatos = () => {
    setModalCorreccionDatos(false)
  }



  const [ show_ModalReporteNomina, setModalReporteNomina ] = useState<boolean>(false)
  const handleShow_ModalReporteNomina = () => {
    setModalReporteNomina(true)
  }
  const handleClose_ModalReporteNomina = () => {
    setModalReporteNomina(false)
  }


  const [ show_ModalEditarDatosEmpleado, setModalEditarDatosEmpleado ] = useState<boolean>(false)
  const handleShow_ModalEditarDatosEmpleado = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalEditarDatosEmpleado(true)
  }
  const handleClose_ModalEditarDatosEmpleado = () => {
    busqueda()
    setModalEditarDatosEmpleado(false)
  }




  const [ show_ModalEditarInformacionNominaEmpleado, setModalEditarInformacionNominaEmpleado ] = useState<boolean>(false)
  const handleShow_ModalEditarInformacionNominaEmpleado = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalEditarInformacionNominaEmpleado(true)
  }
  const handleClose_ModalEditarInformacionNominaEmpleado = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarInformacionNominaEmpleado(false)
  }



  const [ show_ModalBaja, setModalBaja ] = useState<boolean>(false)
  const handleShow_ModalBaja = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalBaja(true)
  }
  const handleClose_ModalBaja = () => {
    setModalBaja(false)
  }



  const [ show_ModalAseguramiento, setModalAseguramiento ] = useState<boolean>(false)
  const handleShow_ModalAseguramiento = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalAseguramiento(true)
  }
  const handleClose_ModalAseguramiento = () => {
    setModalAseguramiento(false)
  }




  const [ show_ModalReingresoEmpleado, setModalReingresoEmpleado ] = useState<boolean>(false)
  const handleShow_ModalReingresoEmpleado = () => {
    setModalReingresoEmpleado(true)
  }
  const handleClose_ModalReingresoEmpleado = () => {
    setModalReingresoEmpleado(false)
  }



  const [ cargando_reporte, setGenerandoReporte ] = useState<boolean>(false)

  const abrirReporteExcel = async () => {

    try{
        
        setGenerandoReporte(true)

        let res = await RequestService.downloadFile(`nomina/base_datos_total_activos`, { })
        if(res){
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Reporte de Empleados `+moment().format('YYYY_MM_DD_HH_mm')+`.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
        setGenerandoReporte(false)
      }
      catch(e){
        toast.error('No pudimos generar el Reporte')
        setGenerandoReporte(false)
      }

  }



      return (
          <>
              



            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">


                      <div className="row">
                        <div className="col-8">
                          
                            <h3>Control del Recurso Humano</h3>
                            <p>{currentUser.iddepartamento.departamento}</p>

                        </div>
                        <div className="col-2  text-right">

                            <h4>QUINCENA</h4>
                            <p>Q{config.nomina_contador}</p>

                        </div>
                        <div className="col-2 text-right">

                            <h4>AÑO</h4>
                            <p>{config.year}</p>                            

                        </div>
                      </div>


                      


                      <div className="row">
                        <div className="col-6">

                            <label htmlFor="exampleFormControlInput1" className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda} className="btn btn-secondary"><i className="fa fa-search"/></button>
                              <div className="vr"></div>
                              <button type="button" onClick={()=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-times"/></button>
                            </div>


                        </div>
                        <div className="col-6">

                            <label htmlFor="exampleFormControlInput1" className="form-label">Filtros</label>

                                <Form.Check
                                  inline
                                  name="group1"
                                  type="checkbox"
                                  label="BASE"
                                  checked={arreglo_nominas[0]}
                                  onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[0] = e.target.checked;  setArregloNominas(ne);}}
                                  id={`opciones_1`}
                                />

                                <Form.Check
                                  inline
                                  name="group2"
                                  type="checkbox"
                                  label="CONFIANZA"
                                  checked={arreglo_nominas[1]}
                                  onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[1] = e.target.checked;  setArregloNominas(ne);}}
                                  id={`opciones_2`}
                                />

                                <Form.Check
                                  inline
                                  name="group3"
                                  type="checkbox"
                                  label="SUPERNUMERARIO"
                                  checked={arreglo_nominas[2]}
                                  onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[2] = e.target.checked;  setArregloNominas(ne);}}
                                  id={`opciones_3`}
                                />

                                <Form.Check
                                  inline
                                  name="group4"
                                  type="checkbox"
                                  label="OTROS"
                                  checked={arreglo_nominas[3]}
                                  onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[3] = e.target.checked;  setArregloNominas(ne);}}
                                  id={`opciones_4`}
                                />

                                <ul className="list-inline">
                                  <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                                </ul>


                        </div>
                      </div>


                            <ul className="list-inline" style={{margin:0}}>



                                {
                                  (['ROLE_ADMIN','MOVIMIENTOS_NOMINA','EJECUTIVO_PRIMARIO'].indexOf(currentUser.rol) > -1) && (
                                    <React.Fragment>
                                        <li className="list-inline-item">
                                            <button type="button" onClick={abrirModalNuevoAsesor} className="btn btn-link" style={{fontSize:12}}>Agregar Empleado</button>
                                        </li>
                                        <li className="list-inline-item">
                                            <button type="button" onClick={handleShow_ModalReingresoEmpleado} className="btn btn-link" style={{fontSize:12}}>Reingresos</button>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="./movimientos" style={{fontSize:12}} className="btn btn-link">Movimientos</a>
                                        </li>
                                    </React.Fragment>
                                  )
                                }



                              <li className="list-inline-item">
                                  <button type="button" onClick={abrirReporteExcel} className="btn btn-link" style={{fontSize:12}}>Reporte Layout</button>
                              </li>




                                {
                                  (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (
                                    <React.Fragment>
                                        <li className="list-inline-item">
                                            <button type="button" onClick={abrirReporteExcel} className="btn btn-link" style={{fontSize:12}}>Base de Datos Total</button>
                                        </li>
                                    </React.Fragment>
                                  )
                                }







                            </ul>

                      

                            <hr className="border border-primary border-3 opacity-75 mb-4"/>












                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5 className="mt-4">Resultados</h5>


                                        <Table responsive="lg">
                                          <thead style={{fontSize:10}}>
                                            <tr>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col">Nombre</th>

                                                {
                                                  ["ROLE_ADMIN"].indexOf(currentUser.rol) > -1 && (<th scope="col"> </th>)
                                                }

                                              <th scope="col">Puesto / Adscripción</th>
                                              <th scope="col">A</th>
                                              <th scope="col">Nivel Jerárquico / Grupo Funcional</th>
                                              <th scope="col">Plaza</th>

                                                {
                                                  ["ROLE_ADMIN"].indexOf(currentUser.rol) > -1 && (<th scope="col"> </th>)
                                                }
                                                


                                                {
                                                  (['ROLE_ADMIN','MOVIMIENTOS_NOMINA','EJECUTIVO_PRIMARIO'].indexOf(currentUser.rol) > -1) && (
                                                    <React.Fragment>
                                                        <th scope="col"></th>
                                                    </React.Fragment>
                                                  )
                                                }
                                                        <th scope="col"></th>

                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (ciudadano:any,indice:number) =>
                                                  <tr key={ indice } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                      <img src={`${SERVER_}empleados/foto/${ciudadano.uuid_ciudadano}?${Math.random().toString(36).substr(2, 9)}`}  style={{width:'100%'}} />
                                                    </td>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                        <small>{ciudadano.numero_empleado}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}}>
                                                        <p className="nm"><small><b>{ciudadano.nombres}</b></small></p>
                                                        <small>{ciudadano.paterno} {ciudadano.materno}</small>
                                                    </td>


                                                    {
                                                      ["ROLE_ADMIN"].indexOf(currentUser.rol) > -1 && (
                                                          <React.Fragment>
                                                            
                                                            <td className="v text-left" style={{"width":"40px"}}>
                                                                  <button type="button" onClick={(e:any)=>{handleShow_ModalEditarDatosEmpleado(ciudadano)}} className="btn btn-sm btn-link"><i className="fa fa-edit"/></button>
                                                            </td>

                                                          </React.Fragment>
                                                        )
                                                    }







                                                    <td className="v text-left" >
                                                        <p className="nm"><b>{ciudadano.puesto.clave_puesto}</b> <small>{ciudadano.puesto.puesto}</small></p>
                                                      <p className="nm"><b>{ciudadano.departamento.clave_departamento}</b> {ciudadano.departamento.departamento}</p>
                                                        
                                                    </td>

                                                                <td className="v" style={{width:20}}>
                                                                  {
                                                                    ciudadano.asegurado && (<i className="fa fa-star" />)
                                                                  }
                                                                </td>
                                                    <td className="v text-left" style={{"width":"280px"}}>
                                                        <p className="nm"><small><b>{ciudadano.grupo_funcional.grupo_funcional}</b></small></p>
                                                        <small>{ciudadano.nivel_jerarquico.id} {ciudadano.nivel_jerarquico.nivel_jerarquico}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        { ciudadano.nomina && (<h6 className="nm"><b>{ciudadano.nomina.nomina}</b></h6>)}
                                                    </td>


                                                    {
                                                      ["ROLE_ADMIN"].indexOf(currentUser.rol) > -1 && (
                                                          <React.Fragment>
                                                            
                                                                <td className="v text-left" style={{"width":"20px"}}>
                                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalEditarInformacionNominaEmpleado(ciudadano)}} className="btn btn-sm btn-link"><i className="fa fa-edit"/></button>
                                                                </td>

                                                          </React.Fragment>
                                                        )
                                                    }







                                                      {
                                                        (['ROLE_ADMIN','MOVIMIENTOS_NOMINA','EJECUTIVO_PRIMARIO'].indexOf(currentUser.rol) > -1) && (
                                                          <React.Fragment>
                                                              <td className="v text-left" style={{"width":"60px"}}>

                                                                    <Dropdown>
                                                                      <Dropdown.Toggle variant="success" size="sm" id="dropdown-basic">
                                                                        <i className="fa fa-navicon" />
                                                                      </Dropdown.Toggle>

                                                                      <Dropdown.Menu>
                                                                        {!ciudadano.asegurado && ( <Dropdown.Item onClick={(e:any)=>{handleShow_ModalAseguramiento(ciudadano)}} ><i className="fa fa-asterisk" /> Alta Aseguramiento  </Dropdown.Item> ) }
                                                                        <Dropdown.Item onClick={(e:any)=>{handleShow_ModalMovimientoEmpleado(ciudadano)}} ><i className="fa fa-exchange" />  Movimiento </Dropdown.Item>
                                                                        <Dropdown.Item onClick={(e:any)=>{handleShow_ModalCorreccionDatos(ciudadano)}} ><i className="fa fa-edit" /> Corrección Datos  </Dropdown.Item>
                                                                        <Dropdown.Item onClick={(e:any)=>{handleShow_ModalBaja(ciudadano)}} ><i className="fa fa-level-down" /> Bajas  </Dropdown.Item>

                                                                      </Dropdown.Menu>
                                                                    </Dropdown>
                                                                  
                                                              </td>
                                                          </React.Fragment>
                                                        )
                                                      }



                                                              <td className="v text-left" style={{"width":"60px"}}>
                                                                  <button type="button" onClick={()=>{handleShow_ModalFichaEmpleado(ciudadano.uuid_ciudadano)}} className="btn btn-sm btn-primary"><i className="fa fa-id-card-o" /></button>
                                                              </td>



                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}








                    
                </div>
              </div>

            </div>







        {

          modalAltaCiudadano && (

            <ModalAltaCiudadano show={modalAltaCiudadano} onHide={onHideModalAltaCiudadano} config={config} />

          )

        }



  {
    show_ModalFichaEmpleado && (
      <ModalFichaEmpleado show={show_ModalFichaEmpleado} handleClose={handleClose_ModalFichaEmpleado} uuid_empleado={uuid_empleado_seleccionado} />
      )

  }



  {
    show_ModalCambiarFoto && (
      <ModalCambiarFoto show={show_ModalCambiarFoto} handleClose={handleClose_ModalCambiarFoto} usuario={empleado_seleccionado} />
      )

  }






  {
    show_ModalReingresoEmpleado && (
      <ModalReingresoEmpleado show={show_ModalReingresoEmpleado} handleClose={handleClose_ModalReingresoEmpleado}  />
      )

  }



  {
    show_ModalMovimientoEmpleado && (
      <ModalMovimientoEmpleado show={show_ModalMovimientoEmpleado} handleClose={handleClose_ModalMovimientoEmpleado} empleado={empleado_seleccionado} />
      )

  }


  {
    // show_ModalMovimientos && (
    //   <ModalMovimientos show={show_ModalMovimientos} handleClose={handleClose_ModalMovimientos} config={config} />
    //   )

  }




  {
    show_ModalCorreccionDatos && (
      <ModalCorreccionDatos show={show_ModalCorreccionDatos} handleClose={handleClose_ModalCorreccionDatos}  empleado={empleado_seleccionado} />
      )

  }



  {
    show_ModalReporteNomina && (
      <ModalReporteNomina show={show_ModalReporteNomina} handleClose={handleClose_ModalReporteNomina} config={config} />
      )

  }



  {
    show_ModalEditarDatosEmpleado && (
      <ModalEditarDatosEmpleado show={show_ModalEditarDatosEmpleado} handleClose={handleClose_ModalEditarDatosEmpleado} empleado={empleado_seleccionado}  />
      )

  }



  {
    show_ModalEditarInformacionNominaEmpleado && (
      <ModalEditarInformacionNominaEmpleado show={show_ModalEditarInformacionNominaEmpleado} handleClose={handleClose_ModalEditarInformacionNominaEmpleado} empleado={empleado_seleccionado} />
      )

  }



  {
    show_ModalBaja && (
      <ModalBaja show={show_ModalBaja} handleClose={handleClose_ModalBaja} empleado={empleado_seleccionado} />
      )

  }


  {
    show_ModalAseguramiento && (
      <ModalAseguramiento show={show_ModalAseguramiento} handleClose={handleClose_ModalAseguramiento} empleado={empleado_seleccionado} />
      )

  }

          </>

      );
  

}

export default Recursos_humanos
