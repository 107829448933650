import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal } from 'react-bootstrap'

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'





const ModalEditarOficio = ( params : any ) => {

  const dialog = new Dialog();

  const mensajes_validacion_generico = 'Este campo es obligatorio'

  const { show, handleClose, oficio } = params


  const schema = yup.object().shape({

          remitente : yup.string().required(mensajes_validacion_generico),
          asunto : yup.string().required(mensajes_validacion_generico),
          observacion : yup.string().nullable(),
          iddepartamento_turnado : yup.string().required(mensajes_validacion_generico),
          numero_oficio : yup.string().required(mensajes_validacion_generico),
          fecha_recepcion : yup.string().required(mensajes_validacion_generico),
          fecha_oficio : yup.string().required(mensajes_validacion_generico),

  });


const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (nuevo_registro:any,event:any,) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)
      // console.log('lo que se envia es:', nuevo_usuario)
        let res_nuevo_usuario = await RequestService.update(`recepcion/registro_oficio/${oficio.uuid}`, { ...nuevo_registro, fecha_recepcion : moment(nuevo_registro.fecha_recepcion).format('YYYY-MM-DD'), fecha_oficio : moment(nuevo_registro.fecha_oficio).format('YYYY-MM-DD') })

        if(res_nuevo_usuario){
          toast.success('La recepción de oficio de actualizó Correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Error al dar de alta.')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  React.useEffect(() => {
    cargarTurnados() 
  }, []);


  const [ turnados, setTurnados] = useState<any>([])

  const cargarTurnados = async() => {

    try{
      let res = await RequestService.getEndPoint('listado_turnados')
      setTurnados(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de turnados')
    }

  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Oficio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            remitente : oficio.remitente,
            asunto : oficio.asunto,
            observacion : oficio.observacion,
            iddepartamento_turnado : oficio.iddepartamento_turnado.uuid,
            numero_oficio : oficio.numero_oficio,
            fecha_recepcion : new Date(moment(oficio.fecha_recepcion).toString()),
            fecha_oficio : new Date(moment(oficio.fecha_oficio).toString()),

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

        {
          // JSON.stringify(values)
        }


            <div className="row">
              <div className="col-sm-6">
                  <Form.Group   className="mb-3" controlId="folio">
                    <Form.Label>Folio</Form.Label>
                    <p>{oficio.folio}</p>
                  </Form.Group>
              </div>
              <div className="col-sm-6">
                  <Form.Group   className="mb-3" controlId="folio">
                    <Form.Label>Número oficio</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="numero_oficio"
                      value={values.numero_oficio}
                      onChange={(e)=>{ setFieldValue('numero_oficio', (e.target.value || "").toUpperCase()) }}
                      isInvalid={!!errors.numero_oficio}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
              </div>
            </div>




            <Form.Group   className="mb-3" controlId="remitente">
              <Form.Label>Remitente</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="remitente"
                value={values.remitente}
                onChange={(e)=>{ setFieldValue('remitente', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.remitente}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group   className="mb-3" controlId="asunto">
              <Form.Label>Asunto</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="asunto"
                value={values.asunto}
                onChange={(e)=>{ setFieldValue('asunto', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.asunto}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group   className="mb-3" controlId="asunto">
              <Form.Label>Observación</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="observacion"
                value={values.observacion}
                onChange={(e)=>{ setFieldValue('observacion', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.observacion}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



            <div className="row">
              <div className="col-sm-6">
                  <div className="mb-3">
                    <Form.Label>Fecha del Oficio</Form.Label>
                    <DatePicker selected={values.fecha_oficio} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_oficio', date ) } />
                  </div>
              </div>
              <div className="col-sm-6">
                  <div className="mb-3">
                    <Form.Label>Fecha de Recepción</Form.Label>
                    <DatePicker selected={values.fecha_recepcion} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_recepcion', date ) } />
                  </div>
              </div>
            </div>

            <Form.Group   controlId="iddepartamento_turnado">
              <Form.Label>Turnado A </Form.Label>
                <select className="form-select" value={values.iddepartamento_turnado} required name="iddepartamento_turnado" onChange={(e)=>{ setFieldValue('iddepartamento_turnado', (e.target.value || "").toUpperCase()) }}>
                  <option value="" disabled>Selecciona una opción</option>
                  { turnados.map( (turnado:any)=> <option key={ Math.random().toString(36).substr(2, 9) } value={turnado.uuid}>{turnado.clave_departamento} {turnado.departamento} </option> ) }
                </select>
              <Form.Control
                type="hidden"
                required
                name="iddepartamento_turnado"
                value={values.iddepartamento_turnado}
                isInvalid={!!errors.iddepartamento_turnado}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


{
  // JSON.stringify(turnados)
}




        </Form>
      )}
    </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarOficio;